import {
  Button,
  FormControl,
  Icon,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import Bureau from 'types/Bureau';
import T from 'utils/translation';
interface BureaFilterProps {
  bureauArr: Bureau[];
  selectedBureauId: string;
  setSelectedBureauId: (bureauId: string) => void;
}
const BureauFilter = (props: BureaFilterProps): JSX.Element => {
  const { bureauArr, setSelectedBureauId } = props;
  const [filterString, setFilterString] = useState<string>('');
  const theme = useTheme();

  return (
    <Wrapper>
      <Typography
        variant="h2"
        sx={{ margin: `${theme.spacing(2)} ${theme.spacing(3)}` }}
      >
        {T('Filter data based on bureau')}
      </Typography>
      <FormControl fullWidth variant="filled">
        <Input
          data-cy="search-bureau-input"
          sx={{
            margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
            height: theme.spacing(6),
            padding: theme.spacing(1),
            backgroundColor: 'grey.50',
          }}
          placeholder={T('Enter search term...')}
          value={filterString}
          onChange={(e) => setFilterString(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <Icon sx={{ color: 'grey.500' }}>search</Icon>
            </InputAdornment>
          }
        />
      </FormControl>
      <Typography
        variant="h3"
        sx={{
          margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
          marginBottom: theme.spacing(2),
        }}
      >
        {T('Bureau')}
      </Typography>
      {[
        { bureauid: '', bureauname: T('All') },
        ...bureauArr.filter(
          (b) =>
            b.bureauname
              .toLocaleLowerCase()
              .indexOf(filterString.toLocaleLowerCase()) > -1,
        ),
      ].map((bureau) => (
        <BureauWrapper key={bureau.bureauid}>
          <Button
            data-cy="bureau-button"
            key={bureau.bureauid}
            sx={{
              padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
              justifyContent: 'left',
              '& .label': {
                textTransform: 'none',
              },
            }}
            fullWidth
            onClick={() =>
              setSelectedBureauId(bureau.bureauid ? bureau.bureauid : '')
            }
          >
            {bureau.bureauname}
          </Button>
        </BureauWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    width: 450,
  },
}));

const BureauWrapper = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export default BureauFilter;
