import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useCookies } from 'react-cookie';
import CookieConsentLib from 'react-cookie-consent';
import ReactGA from 'react-ga';
import T from 'utils/translation';
import { useTheme } from '@mui/material/styles';
import './cookie-consent.css';

const CookieConsent: React.FC<{ activeSession: boolean; page?: string }> = ({
  activeSession,
  page,
}) => {
  const [consentCookies] = useCookies(['mtcookieconsent']);
  const handleConsent = (agree?: boolean) => {
    window['ga-disable-UA-109136730-6'] =
      agree !== undefined
        ? !agree
        : !(
            consentCookies?.mtcookieconsent === 'true' ||
            consentCookies?.mtcookieconsent === 'allow'
          );
    ReactGA.initialize('UA-109136730-6');
    ReactGA.pageview(page || '');
  };

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    handleConsent();
  }, []);

  return (
    <CookieConsentLib
      cookieName="mtcookieconsent"
      enableDeclineButton
      declineButtonText={T('Close')}
      buttonText={T('Allow')}
      containerClasses={`container 
        ${activeSession ? 'container-logged-in' : ''}
        ${mediumScreen ? 'container-md-down' : ''}
      `}
      buttonWrapperClasses={'button-wrapper'}
      buttonClasses={`button accept-button ${
        activeSession ? 'accept-button-logged-in' : ''
      }`}
      declineButtonClasses={`button ${
        activeSession ? 'decline-button-logged-in' : ''
      }`}
      disableStyles
      onAccept={() => handleConsent(true)}
      onDecline={() => handleConsent(false)}
      location="top"
    >
      <div>
        {T('We are using cookies which makes this website work better.') + ' '}
        <a
          target="blank"
          href="https://www.millnet.se/om-cookies/sidor/om-cookies"
        >
          {T('Read more')}
        </a>
      </div>
    </CookieConsentLib>
  );
};

export default CookieConsent;
