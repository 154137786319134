import { AlertColor } from '@mui/material';
import {
  APINotificationContext,
  Notification,
  TimeOutDuration,
} from 'components/NotificationProvider';
import { useCallback, useContext } from 'react';
import T from 'utils/translation';

type UseNotificationReturns = {
  addNotification: (
    message: string,
    status?: AlertColor,
    timeOut?: TimeOutDuration,
  ) => void;
  notification?: Notification;
  showServerError: () => void;
};

const useNotification = (): UseNotificationReturns => {
  const { addNotification, notification } = useContext(APINotificationContext);
  const showServerError = useCallback(
    () => addNotification(T('Network error'), 'error'),
    [addNotification],
  );
  return { addNotification, notification, showServerError };
};

export default useNotification;
