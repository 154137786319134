import { api } from './api';
import { PID } from 'types/PID';

const pid = {
  url: 'pids',
  /*
  get: (): Promise<PID[]> =>
    api
      .getJson(pic.url)
      .then((rows) =>
        rows.map((d: PID) => ({ ...d, created: new Date(d.created) }))
      ),
      */
  post: (body: PID): Promise<PID> => {
    return api.postJson(pid.url, body);
  },
};

export default pid;
