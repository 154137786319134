import { api } from './api';

import ClientInfo from 'types/ClientInfo';
import ClientInfoApi from 'types/ClientInfoApi';
import { dateToRegMonth } from 'utils/format';

const timereport = {
  url: 'clients/status',
  get: (month: Date, bureauFilter: string): Promise<ClientInfo[]> => {
    return api
      .getJson(
        `${timereport.url}/${dateToRegMonth(month)}${
          bureauFilter ? '/' + bureauFilter : ''
        }`,
      )
      .then((data: ClientInfoApi[]) => {
        const clientInfo: ClientInfo[] = data.map((clientInfo) => {
          return {
            ClientId: clientInfo.clientid,
            ClientName: clientInfo.clientname,
            ClientUrl: clientInfo.clienturl,
            LastAttestDate: clientInfo.lastattestdate,
            LastAttestUser: clientInfo.lastattestuser,
            LastPayrollCount: clientInfo.lastpayrollcount,
            LastPayrollDate: clientInfo.lastpayrolldate,
            LastUpdated: clientInfo.lastupdated,
            LicenseCount: clientInfo.licensecount,
            PayrollExpCount: clientInfo.payrollexpcount,
            RegMonth: clientInfo.regmonth,
            TimeReportAttestCount: clientInfo.timereportattestcount,
            TimeReportExpCount: Math.max(
              0,
              clientInfo.timereportexpcount -
                clientInfo.timereportinactiveexpcount,
            ),
            TimeReportInactiveExpCount: clientInfo.timereportinactiveexpcount,
            TimeReportLockCount: clientInfo.timereportlockcount,
            TimeReportLockedLeft: Math.max(
              0,
              clientInfo.timereportexpcount -
                clientInfo.timereportinactiveexpcount -
                clientInfo.timereportlockcount,
            ),
            TimeReportAttestedLeft: Math.max(
              0,
              clientInfo.timereportexpcount -
                clientInfo.timereportinactiveexpcount -
                clientInfo.timereportattestcount,
            ),
          };
        });
        return clientInfo;
      });
  },
};

export default timereport;
