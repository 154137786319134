import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';

const grey = {
  extraLightGrey: '#F8F8F8',
  lightGrey: '#EEEEEE',
  mediumGrey: '#E6E6E6',
  grey: '#BBBBBB',
  darkGrey: '#7b7b7b',
};
export const defaultPrimary = '#6c5198';
const spacing = 6;
const createTheme = (mainColor?: string): Theme =>
  createMuiTheme(
    {
      spacing: spacing,
      palette: {
        primary: {
          main: mainColor || defaultPrimary,
        },
        success: {
          main: '#5B9852',
          contrastText: '#fff',
        },
        error: {
          main: '#FF5E60',
          contrastText: '#fff',
        },
        grey: {
          100: grey.extraLightGrey,
          200: grey.lightGrey,
          300: grey.mediumGrey,
          400: grey.grey,
          600: grey.darkGrey,
        },
      },
      typography: {
        fontFamily: 'Roboto',
        allVariants: {
          color: 'black',
          font: 'Roboto',
          fontWeight: 400,
        },
        h1: {
          fontWeight: 500,
          fontSize: '20px',
        },
        h2: {
          fontSize: '18px',
          fontWeight: 500,
        },
        h3: {
          lineHeight: '1.65',
          fontWeight: 500,
          fontSize: '16px',
        },
        h4: {
          lineHeight: '1.65',
          fontWeight: 500,
          fontSize: '14px',
        },
        h5: {
          fontSize: '12px',
          fontWeight: 500,
        },
        h6: {
          fontWeight: 500,
          fontSize: '11px',
        },
        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightLight: 300,
        fontWeightRegular: 400,
      },
      // Components override
      components: {
        MuiAlert: { defaultProps: { variant: 'filled', elevation: 6 } },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              paddingTop: spacing * 4,
              fontSize: '18px',
            },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: { fontSize: 'small', color: grey.darkGrey },
            root: {
              justifyContent: 'space-between',
              marginLeft: 0,
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              color: grey.darkGrey,
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              fontSize: 14,
            },
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
            InputLabelProps: { shrink: true },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: '#000',
              margin: 2,
              fontSize: 14,
              fontWeight: 500,
              maxWidth: 600,
              textOverflow: 'ellipsis',
              borderRadius: 3,
            },
          },
        },
      },
    },
    {
      components: {
        MuiCalendarPicker: {
          styleOverrides: {
            root: {
              '& button.PrivatePickersMonth-root.Mui-selected:hover': {
                backgroundColor: mainColor,
              },
              '& div.PrivatePickersYear-root button.Mui-selected:hover': {
                backgroundColor: mainColor,
              },
              '& div.PrivatePickersYear-root button.Mui-selected:focus': {
                backgroundColor: mainColor,
              },
            },
          },
        },
      },
    },
  );

export default createTheme;
