import { AlertColor } from '@mui/material';
import React, { useState, useCallback } from 'react';
import FCProps from 'types/FCProps';

export type TimeOutDuration = 'short' | 'long';

export type Notification = {
  message: string;
  status: AlertColor;
  timeOut: TimeOutDuration | null;
};

type ApiNotificationContextContent = {
  notification?: Notification;
  addNotification: (
    message: string,
    status?: AlertColor,
    timeOut?: TimeOutDuration | null,
  ) => void;
};

export const APINotificationContext =
  React.createContext<ApiNotificationContextContent>({
    notification: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addNotification: () => {},
  });

const NotificationProvider: React.FC<FCProps> = ({ children }) => {
  const [notification, setNotification] = useState<Notification>();

  const addNotification = (
    message: string,
    status: AlertColor = 'info',
    timeOut: TimeOutDuration | null = null,
  ) => setNotification({ message, status, timeOut });

  const contextValue = {
    notification,
    addNotification: useCallback(
      (
        message: string,
        status?: AlertColor,
        timeOut?: TimeOutDuration | null,
      ) => addNotification(message, status, timeOut),
      [],
    ),
  };
  return (
    <APINotificationContext.Provider value={contextValue}>
      {children}
    </APINotificationContext.Provider>
  );
};

export default NotificationProvider;
