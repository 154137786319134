import { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Popover,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import RoundedButton from 'components/RoundedButton';
import Bureau from 'types/Bureau';
import bureauApi from 'api/bureau';
import T from 'utils/translation';
import BureauFilter from './BureaFilter';
import Session from 'types/Session';
import DatePickerIcon from '@mui/icons-material/InsertInvitation';

interface StartPageFilterProps {
  month: Date;
  setMonth: (newDate: Date) => void;
  filterText: string;
  setFilterText: (newText: string) => void;
  setBureau: (newBureau: string) => void;
  updatedTs: string;
  session: Session;
}
const StartPageFilter = (props: StartPageFilterProps): JSX.Element => {
  const {
    month,
    setMonth,
    filterText,
    setFilterText,
    updatedTs,
    setBureau,
    session,
  } = props;
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [bureauArr, setBureauArr] = useState<Bureau[]>([]);
  const [bureauFilter, setBureauFilter] = useState<string>('');
  const [bureauSelectOpen, setBureauSelectOpen] = useState<boolean>(false);
  const [bureauPopoverAnchorEl, setBureauPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleMonthStep = (step: 'up' | 'down'): void => {
    const newMonth = new Date(month);
    newMonth.setMonth(month.getMonth() + (step === 'up' ? 1 : -1));
    setMonth(new Date(newMonth));
  };
  useEffect(() => {
    if (session.role === 'superadmin') {
      bureauApi
        .get()
        .then((data) => {
          if (data) {
            setBureauArr(
              [...data.map((b) => b)].sort((a, b) => {
                return a.bureauname > b.bureauname ? 1 : -1;
              }),
            );
          }
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  }, []);
  const datePicker = (
    <>
      <DatePicker
        slotProps={{
          field: { sx: { fontSize: 14 } },
          textField: {
            style: { color: 'primary' },
          },
          dialog: {
            sx: {
              '& .MuiPickersMonth-root': {
                fontWeight: 400,
              },
              '& .MuiPickersMonth-monthSelected': {
                fontSize: 25,
              },
              '& .MuiPickersYear-yearSelected': {
                fontSize: 25,
              },
            },
          },
        }}
        slots={{
          openPickerButton: (props) => (
            <IconButton {...props} data-cy="date-input">
              <DatePickerIcon />
            </IconButton>
          ),
        }}
        label={T('Select month')}
        views={['month', 'year']}
        format="yyyy-MM"
        value={month}
        onChange={(date) => {
          if (date && date.toString() !== 'Invalid Date') {
            setMonth(date);
          }
        }}
        sx={{
          fontSize: 14,
          color: theme.palette.primary.main,
          [theme.breakpoints.down('sm')]: {
            maxWidth: 175,
          },
        }}
      />
      <IconButton onClick={() => handleMonthStep('down')} size="large">
        <Icon>keyboard_arrow_left</Icon>
      </IconButton>
      <IconButton onClick={() => handleMonthStep('up')} size="large">
        <Icon>keyboard_arrow_right</Icon>
      </IconButton>
    </>
  );
  const filterInput = (
    <>
      <FormControl
        fullWidth
        variant="filled"
        sx={{
          height: theme.spacing(6),
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <Input
          data-cy="search-input"
          sx={{
            height: theme.spacing(6),
            padding: theme.spacing(1),
            backgroundColor: theme.palette.grey[50],
          }}
          placeholder={T('Enter search term...')}
          endAdornment={
            <InputAdornment position="end">
              <Icon
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                search
              </Icon>
            </InputAdornment>
          }
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormControl>
    </>
  );
  const bureauSelect =
    session.role === 'superadmin' ? (
      <>
        <Tooltip
          title={
            T('Data from') +
            ': ' +
            (bureauArr.find((b) => b.bureauid === bureauFilter)?.bureauname ||
              T('All'))
          }
        >
          <IconButton
            data-cy="filter-button"
            sx={{
              paddingTop: theme.spacing(2),
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setBureauPopoverAnchorEl(event.currentTarget);
              setBureauSelectOpen(!bureauSelectOpen);
            }}
            size="large"
          >
            <Icon>sync_alt</Icon>
          </IconButton>
        </Tooltip>
        <Popover
          sx={{
            padding: `${theme.spacing(3)} 0`,
          }}
          open={bureauSelectOpen}
          anchorEl={bureauPopoverAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onBackdropClick={() => setBureauSelectOpen(false)}
        >
          <BureauFilter
            bureauArr={bureauArr}
            selectedBureauId={bureauFilter}
            setSelectedBureauId={(bureauid) => {
              setBureauSelectOpen(false);
              setBureauFilter(bureauid);
              setBureau(bureauid);
            }}
          />
        </Popover>
      </>
    ) : null;
  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
    setShowPopover(!showPopover);
  };
  const filterButton = (
    <>
      <RoundedButton
        color="primary"
        variant="contained"
        startIcon={<Icon>filter_list</Icon>}
        onClick={handleOpenFilter}
      >
        {T('Filter')}
      </RoundedButton>
      <Popover
        sx={{
          marginTop: theme.spacing(1),
          padding: theme.spacing(2),
          borderRadius: 3,
        }}
        anchorEl={popoverAnchorEl}
        open={showPopover}
        onBackdropClick={() => setShowPopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setPopoverAnchorEl(null)}
      >
        <div key="datepicker">{datePicker}</div>
        <div key="filterinput">{filterInput}</div>
        <div key="bureauselect">{bureauSelect}</div>
      </Popover>
    </>
  );
  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {!smallScreen && datePicker}
        <Box>
          <TextField
            InputProps={{ disableUnderline: true }}
            value={updatedTs}
            disabled
            label={T('Updated at')}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          flexGrow={1}
          sx={{
            paddingTop: '10px',
            textAlign: 'right',
          }}
        >
          {smallScreen ? filterButton : filterInput}
        </Box>
        {!smallScreen && <Box>{bureauSelect}</Box>}
      </Box>
    </Paper>
  );
};

export default StartPageFilter;
