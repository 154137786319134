import { useState, useEffect } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { getDatefnsSeLocale } from 'utils/format';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
//
import createTheme from './theme';
import LoginPage from 'pages/login/LoginPage';
import PageNavigation from './PageNavigation';
import Session, { Role } from 'types/Session';
import sessionApi from 'api/session';
import SessionContext from './SessionContext';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import { HashRouter } from 'react-router-dom';
import NotificationProvider from 'components/NotificationProvider';
import GlobalNotificaiton from 'components/GlobalNotification';

declare global {
  interface Window {
    'ga-disable-UA-109136730-6': boolean;
  }
}

window['ga-disable-UA-109136730-6'] =
  window['ga-disable-UA-109136730-6'] || false;

function App(): JSX.Element {
  const [activeSession, setActiveSessionState] = useState<Session | false>(
    false,
  );
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);
  const [configChecked, setConfigChecked] = useState<boolean>(false);
  const [readyToShow, setReadyToShow] = useState<boolean>(false);
  const [primaryColor, setPrimaryColor] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setReadyToShow(true);
    }, 1000);
    fetch('https://cdn1.millnet.cloud/customer_config.cgi')
      .then((resp) => resp.json())
      .then((config) => {
        if (!readyToShow && config?.theme?.['primary-color']?.rgb) {
          setPrimaryColor(config.theme['primary-color'].rgb);
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => console.error('Failed loading cdn config'))
      .finally(() => {
        setConfigChecked(true);
      });
  }, []);
  useEffect(() => {
    fetch('https://cdn1.millnet.cloud/logo.cgi?product=sc').then(
      (res: Response) => {
        if (res.statusText === 'OK' && res.url) {
          setLogoUrl(res.url);
        }
      },
    );
  }, []);
  useEffect(() => {
    if (!sessionChecked) {
      sessionApi
        .get()
        .then((session) => {
          setActiveSessionState(session);
        })
        .catch(() => setActiveSessionState(false))
        .finally(() => setSessionChecked(true));
    }
  }, []);
  useEffect(() => {
    if (sessionChecked && configChecked && logoUrl !== '') {
      setReadyToShow(true);
    }
  }, [sessionChecked, configChecked, logoUrl]);

  const theme = createTheme(primaryColor);

  const sessionContextValue = {
    role: !activeSession ? undefined : activeSession.role,
    userid: !activeSession ? -1 : activeSession.userid,
    bureauid: !activeSession ? '' : activeSession.bureauid,
    setActiveSession: (session: Session | false) => {
      if (!!session) {
        setActiveSessionState(session);
      } else if (session === false && !!activeSession) {
        sessionApi.delete().finally(() => setActiveSessionState(false));
      }
    },
    hasAtLeastRole: (role: Role) => {
      if (!activeSession) {
        return false;
      }
      const orderOfImportance: Role[] = ['user', 'admin', 'superadmin'];
      const userWeight = orderOfImportance.indexOf(activeSession.role as Role);
      const requestedWeight = orderOfImportance.indexOf(role);
      return userWeight >= requestedWeight;
    },
  };
  return readyToShow === true ? (
    <SessionContext.Provider value={sessionContextValue}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={getDatefnsSeLocale()}
        >
          <ThemeProvider theme={theme}>
            <NotificationProvider>
              <HashRouter>
                {activeSession ? (
                  <PageNavigation logoUrl={logoUrl} />
                ) : (
                  <>
                    <CookieConsent
                      activeSession={activeSession}
                      page={'login'}
                    />
                    <LoginPage logoUrl={logoUrl} />
                  </>
                )}
              </HashRouter>
              <GlobalNotificaiton />
            </NotificationProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </SessionContext.Provider>
  ) : (
    <div />
  );
}

export default App;
