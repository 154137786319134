import React, { CSSProperties } from 'react';

interface EmStringProps {
  string: string;
  emString: string;
  style?: CSSProperties;
}
const EmphasisString = ({
  string,
  emString,
  style,
}: EmStringProps): JSX.Element => {
  if (
    !string ||
    emString === '' ||
    typeof emString !== 'string' ||
    emString.length < 1
  ) {
    return <div style={style}>{string}</div>;
  }
  const parts = [];
  const str = String(string);
  let offset = 0,
    foundIdx = 0;
  while (foundIdx > -1) {
    foundIdx = str.toLowerCase().indexOf(emString.toLowerCase(), offset);
    if (foundIdx > -1) {
      parts.push(str.substr(offset, foundIdx - offset));
      parts.push(
        <span key={offset} style={{ fontWeight: 600 }}>
          {str.substr(foundIdx, emString.length)}
        </span>,
      );
      offset = foundIdx + emString.length;
    } else {
      parts.push(
        <React.Fragment key={offset}>
          {str.substr(offset, str.length)}
        </React.Fragment>,
      );
    }
  }
  return <div style={style}>{parts}</div>;
};

export default EmphasisString;
