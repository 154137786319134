import React, { useEffect } from 'react';
import { GridColDef, GridCellParams } from '@mui/x-data-grid-pro';
import FilteredDataGrid from 'components/FilteredDataGrid';
import T from 'utils/translation';
import bureauApi from 'api/bureau';
import picApi from 'api/pic';
import Bureau from 'types/Bureau';
import { PIC } from 'types/PIC';

const History = (): JSX.Element => {
  const [dataArr, setDataArr] = React.useState<PIC[]>([]);
  const [bureauArr, setBureauArr] = React.useState<Bureau[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'instancename',
      headerName: T('Client'),
      flex: 1,
    },
    {
      field: 'bureauid',
      headerName: T('Bureau'),
      flex: 1,
      valueGetter: (value) => {
        return bureauArr.find(
          (bureau) => bureau.bureauid === value.row.bureauid,
        )?.bureauname;
      },
    },
    {
      field: 'orgnr',
      headerName: T('Organisation number'),
      flex: 1,
    },
    {
      field: 'created',
      headerName: T('Created'),
      flex: 1,
      valueGetter: (value) => {
        const date = dataArr
          .find((date) => date.instanceid === value.row.instanceid)
          ?.created.toLocaleDateString();
        const time = dataArr
          .find((date) => date.instanceid === value.row.instanceid)
          ?.created.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });
        return `${date} ${time}`;
      },
    },
    {
      field: 'createdby',
      headerName: T('Created by'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: T('Status'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueGetter: (value) => {
        return T(value.row.status);
      },
      renderCell: (params: GridCellParams) => {
        const backgroundColor =
          params.value === T('FINISHED')
            ? '#54A248'
            : params.value === T('PENDING')
              ? '#FFB451'
              : '#FF5E5B';
        return (
          <div
            style={{
              width: 104,
              height: 24,
              borderRadius: 2,
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              backgroundColor: backgroundColor,
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    updateTable();
  }, []);

  const updateTable = () => {
    picApi.get().then((res) => {
      setDataArr(res);
    });
    bureauApi.get().then((res) => {
      setBureauArr(res);
    });
  };

  return (
    <>
      <FilteredDataGrid<PIC>
        rows={dataArr}
        disableCreate
        columns={columns}
        rowIdentifier="instanceid"
        filterFields={['instancename', 'status']}
        sortBy={{
          field: 'status',
          order: 'asc',
        }}
      />
    </>
  );
};

export default History;
