import { Snackbar, Alert, AlertColor } from '@mui/material';
import useNotification from 'hooks/useNotification';
import { useEffect, useState } from 'react';
import { TimeOutDuration } from './NotificationProvider';

const GlobalNotificaiton: React.FC = () => {
  const { notification } = useNotification();
  // Hold status here as to not have the status change when notification is in fade-out animation.
  const [internalStatus, setInternalStatus] = useState<AlertColor>();
  const [open, setOpen] = useState<boolean>(false);
  const [autoHideDuration, setAutoHideDuration] =
    useState<TimeOutDuration | null>(null);
  useEffect(() => {
    if (notification) {
      setInternalStatus(notification.status);
      setOpen(!!notification);
      setAutoHideDuration(notification.timeOut);
    }
  }, [notification]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      autoHideDuration={
        autoHideDuration && (autoHideDuration === 'long' ? 5000 : 2000)
      }
      open={open}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert onClose={handleClose} severity={internalStatus}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalNotificaiton;
