import featureFlag from 'api/featureflag';
import { useEffect, useState } from 'react';

/**
 * Custom hook that returns true if the specified feature flag
 * is enabled (either for everyone, or just for this particular session
 * user), and false otherwise
 * @param flagName Feature flag name (case insensitive), as specified in the database table `featureflag`
 * @returns True if feature flag is enabled
 * @example const hasFeature = useFeatureFlag('BB-412');
 */
export default function useFeatureFlag(flagName: string): boolean | undefined {
  if (flagName.trim().length == 0) {
    throw new TypeError('Expected a feature flag name, received empty string');
  }
  const [enabled, setEnabled] = useState<boolean>();
  useEffect(() => {
    featureFlag
      .get()
      .then((flags) =>
        setEnabled(
          flags.find(
            ({ flagname }) => flagname.toLowerCase() === flagName.toLowerCase(),
          ) !== undefined,
        ),
      );
  }, []);
  return enabled;
}
