import { Divider, Paper, Tabs, Tab } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useContext, useState, useMemo, useEffect } from 'react';
import T from 'utils/translation';
import { Role } from 'types/Session';
import SessionContext from 'SessionContext';
import {
  Link,
  useRouteMatch,
  useLocation,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Users from 'pages/admin/Users';
import Clients from 'pages/admin/Clients';
import Bureaux from 'pages/admin/Bureaux';
import History from 'pages/admin/History';

interface AdminTab {
  label: string;
  href: string;
  component: JSX.Element;
  role?: undefined | Role;
  dataCy?: string;
}

const tabs: AdminTab[] = [
  {
    label: T('Users'),
    href: 'users',
    component: <Users />,
    dataCy: 'users-button',
  },
  {
    label: T('Clients'),
    href: 'clients',
    component: <Clients />,
    dataCy: 'clients-button',
  },
  {
    label: T('Bureaux'),
    role: 'superadmin',
    href: 'bureaux',
    component: <Bureaux />,
    dataCy: 'bureaux-button',
  },
];

const tabHistory: AdminTab[] = [
  {
    label: T('History'),
    href: 'history',
    component: <History />,
  },
];

/**
 * A "safe" tab to fall back to, e.g. something all
 * admins may see. Should be a tab that hasn't a "role"
 * requirement (such as the Users-tab).
 */
const safeFallbackTab: AdminTab = tabs[0];

const Admin = (): JSX.Element => {
  const sessionContext = useContext(SessionContext);
  const [activeTab, setActiveTab] = useState<string>(safeFallbackTab.href);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const theme = useTheme();

  const availableTabs = useMemo(() => {
    return tabs.filter((tab) => {
      let showTab = true;
      if (tab.role) {
        showTab = sessionContext.hasAtLeastRole(tab.role);
      }
      return showTab;
    });
  }, [SessionContext]);

  const allTabs = [...availableTabs, ...tabHistory];

  useEffect(() => {
    const wantedTab =
      allTabs.find((tab) => pathname.endsWith(tab.href)) || safeFallbackTab;
    setActiveTab(wantedTab.href);
  }, [pathname, availableTabs]);

  const tabLink = (tab: AdminTab): string => url + '/' + tab.href;

  const tabTestingAttribute = (tab: AdminTab): string => tab.dataCy || '';

  return (
    <Root>
      <Paper sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <Tabs
          sx={{
            padding: '16px',
            '.MuiTabs-flexContainer': {
              gap: '16px',
            },
            '.MuiTabs-indicator': {
              display: 'none',
            },
          }}
          value={activeTab}
          orientation="vertical"
          onChange={(e, value) => setActiveTab(value)}
        >
          {availableTabs.map((tab, tabIndex) => (
            <Tab
              data-cy={tabTestingAttribute(tab)}
              component={Link}
              replace={true}
              to={tabLink(tab)}
              sx={{
                padding: '9.25px 12px',
                minHeight: '0px',
                textTransform: 'initial',
                alignItems: 'flex-start',
                '&:hover': {
                  background: '#f3f1f7',
                  borderRadius: '3px',
                },
                transition: '0.3s',
                '&.Mui-selected': {
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '3px',
                  fontWeight: '500',
                },
              }}
              key={tabIndex}
              value={tab.href}
              label={tab.label}
            />
          ))}
          <Tab
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              margin: 0,
              minHeight: 0,
              '&.MuiButtonBase-root': {
                minHeight: 0,
                padding: 0,
              },
            }}
          />
          {tabHistory.map((tab, tabIndex) => (
            <Tab
              data-cy="history-button"
              component={Link}
              replace={true}
              to={tabLink(tab)}
              sx={{
                padding: '9.25px 12px',
                minHeight: 0,
                textTransform: 'initial',
                alignItems: 'flex-start',
                '&:hover': {
                  background: '#f3f1f7',
                  borderRadius: '3px',
                },
                transition: '0.3s',
                '&.Mui-selected': {
                  color: `${theme.palette.primary.contrastText}`,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '3px',
                  fontWeight: '500',
                },
              }}
              key={tabIndex}
              value={tab.href}
              label={tab.label}
            />
          ))}
        </Tabs>
        <Divider orientation="vertical" flexItem />
        <Switch>
          {allTabs.map((tab) => (
            <Route
              key={tab.href}
              path={tabLink(tab)}
              children={tab.component}
            />
          ))}
          <Redirect to={tabLink(safeFallbackTab)} />
        </Switch>
      </Paper>
    </Root>
  );
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f8f8',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export default Admin;
