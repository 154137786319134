import {
  FormControl,
  Icon,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material';
import RoundedButton from 'components/RoundedButton';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useState } from 'react';
import T from 'utils/translation';
import { useTheme } from '@mui/material/styles';
import session from 'api/session';
import SessionContext from 'SessionContext';

interface ILoginPageProps {
  logoUrl: string;
}
const LoginPage: React.FC<ILoginPageProps> = (props) => {
  const { logoUrl } = props;
  const [loginError, setLoginError] = useState<string>('');
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const sessionContext = useContext(SessionContext);
  const handleLogin = (event: React.SyntheticEvent) => {
    event.preventDefault();
    session
      .post(username, password)
      .then((session) => {
        sessionContext.setActiveSession(session);
      })
      .catch(() => {
        sessionContext.setActiveSession(false);
        // Assume bad username/password
        setLoginError(T('Wrong username or password'));
      });
  };
  const theme = useTheme();
  const setBgCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const bgImage = new Image();
      const imageSrc = 'https://cdn1.millnet.cloud/wallpaper.cgi';
      bgImage.onload = () => {
        node.style.backgroundImage = `url(${imageSrc})`;
        node.style.opacity = '1';
      };
      bgImage.src = imageSrc;
    }
  }, []);
  return (
    <Root>
      <Background ref={setBgCallback} />
      <form onSubmit={handleLogin}>
        <Paper
          sx={{
            width: 320,
            boxSizing: 'border-box',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            background: 'rgba(255,255,255,0.75)',
            borderRadius: 5,
            textAlign: 'center',
            padding: `0 ${theme.spacing(7)}`,
          }}
        >
          <Typography
            variant="h1"
            color="primary"
            sx={{
              margin: `${theme.spacing(5)} ${theme.spacing(2)}`,
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
          >
            {logoUrl && <Logo src={logoUrl} />}
          </Typography>
          <FormControl fullWidth>
            <InputLabel
              color="primary"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 400,
              }}
              shrink
            >
              {T('Username')}
            </InputLabel>
            <Input
              placeholder={T('Enter username')}
              fullWidth
              inputProps={{ 'data-cy': 'login-username' }}
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ marginTop: theme.spacing(4) }} fullWidth>
            <InputLabel
              color="primary"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 400,
              }}
              shrink
            >
              {T('Password')}
            </InputLabel>
            <Input
              inputProps={{ 'data-cy': 'login-password' }}
              type="password"
              placeholder={T('Enter password')}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <ButtonWrapper>
            <RoundedButton
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleLogin}
              data-cy="login-submit"
            >
              {T('Login')}
            </RoundedButton>
          </ButtonWrapper>
          {loginError.length > 0 && (
            <ErrorWrapper>
              <Icon
                sx={{
                  verticalAlign: 'middle',
                  margin: `0 ${theme.spacing(2)}`,
                }}
              >
                warning
              </Icon>
              {loginError}
            </ErrorWrapper>
          )}
        </Paper>
      </form>
    </Root>
  );
};

const Root = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
});

const Background = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  opacity: 0,
  transition: 'opacity 1.5s',
});

const Logo = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
});

const ButtonWrapper = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(5)} auto`,
}));

const ErrorWrapper = styled('div')(({ theme }) => ({
  fontSize: 13,
  borderRadius: '0 0 5px 5px',
  margin: `0px -${theme.spacing(7)}`,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  textAlign: 'left',
  padding: `${theme.spacing(1)}`,
}));

export default LoginPage;
