import React from 'react';
import Session, { Role } from 'types/Session';
export interface SessionCtx extends Session {
  setActiveSession: (session: false | Session) => void;
  hasAtLeastRole: (role: Role) => boolean;
}
const SessionContext = React.createContext<SessionCtx>({
  role: undefined,
  userid: -1,
  bureauid: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveSession: () => {},
  hasAtLeastRole: () => false,
});

export default SessionContext;
