import seLocale from 'date-fns/locale/sv';

const leadingZero = (val: number): string => {
  return `${val < 10 ? '0' : ''}${val}`;
};
//TODO: timezone problem ?
export const dateToDateTime = (date: Date): string => {
  return `${date.getFullYear()}-${leadingZero(date.getMonth())}-${leadingZero(
    date.getDate(),
  )} - ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}`;
};

export const dateToRegMonth = (date: Date): string => {
  const d = new Date(date);
  d.setDate(1);
  return d.toISOString().substr(0, 10);
};

export const ensureHttpsUrl = (url: string): string => {
  if (!url) {
    return url; // Empty string
  }
  const httpsregex = /^https:\/\/.+$/;
  return httpsregex.test(url) ? url : `https://${url}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const datefnsSeLocale: any = {
  ...seLocale,
  localize: {
    ...seLocale.localize,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    month: (monthIndex: number, formatOptions: any): string => {
      const shortNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Maj',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ];
      const fullNames = [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
      ];
      if (formatOptions.width === 'abbreviated') {
        return shortNames[monthIndex];
      } else if (formatOptions.width === 'wide') {
        return fullNames[monthIndex];
      } else {
        return seLocale.localize?.month(monthIndex, formatOptions) ?? '';
      }
    },
  },
};
// Dirty override to have our own preferences to month names
export const getDatefnsSeLocale = (): Locale => {
  return datefnsSeLocale;
};
