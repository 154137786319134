import { api } from './api';
import Client from 'types/Client';

const client = {
  url: 'clients',
  get: (): Promise<Client[]> => api.getJson(client.url),
  put: (id: number, body: Client): Promise<Client> => {
    return api.put(`${client.url}/${id}`, body);
  },
};

export default client;
