/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from './api';

const session = {
  url: 'session',
  get: (): Promise<any> => api.getJson(session.url),
  post: (email: string, password: string): Promise<any> => {
    return api.postJson(session.url, {
      email,
      password,
    });
  },
  delete: (): Promise<any> => {
    return api.delete(session.url, null);
  },
};

export default session;
