import { useMemo, useState } from 'react';
import { Button, Icon } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
  LicenseInfo,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import T, { getDataGridLocale } from 'utils/translation';
import { Grid, TextField, InputAdornment, Box } from '@mui/material';
import EmphasisString from './EmphasisString';

LicenseInfo.setLicenseKey(
  '650cd25201a5154f10b7d32e769586f2T1JERVI6Mjg5MzEsRVhQSVJZPTE2NjIyMDQzNzAwMDAsS0VZVkVSU0lPTj0x',
);

interface CreateProps {
  /**
   * Label of the "Create ..." button
   */
  label: string;
  /**
   * Handler to invoke when clicking the "Create ..." button
   */
  onCreateClick: () => void;
  /**
   * If true, don't display the "Create ..." button
   * @default false
   */
  disableCreate?: boolean;
  createButtonCy?: string;
}

interface SortProps<Tmpl> {
  /**
   * Field of the model (e.g. that is of the Tmpl-type) to be used
   * for sorting.
   */
  field: keyof Tmpl | '';
  /**
   * Sorting order
   */
  order?: GridSortDirection;
}

interface GridProps<Tmpl> {
  /**
   * Source of the table rows (e.g. the data)
   */
  rows: Tmpl[];
  /**
   * Field of the model (e.g. that is of the Tmpl-type) to be used
   * as a row identifier.
   * @default "id"
   */
  rowIdentifier?: keyof Tmpl;
  /**
   * Columns of the table grid
   */
  columns: GridColDef[];
  /**
   * Sort the table initially using this key (e.g. a field
   * present on the provided model)
   */
  sortBy?: SortProps<Tmpl>;
  /**
   * Handler to invoke when clicking a row in the table.
   * @param row Model instance of that row
   */
  onRowClick?: (row: Tmpl) => void;
  /**
   * Handler to invoke when clicking a cell in the table.
   * @param field Name of field (e.g. column) that was clicked
   * @param row Model instance of that row
   * @param event Event generated. Can be used to prevent propagation
   */
  onCellClick?: (
    field: string,
    row: Tmpl,
    event: MuiEvent<React.MouseEvent>,
  ) => void;
  /**
   * Additional props that are sent directly to the
   * DataGridPro-component
   */
  DataGridProps?: Partial<DataGridProProps>;
}

interface FilterProps<Tmpl> {
  /**
   * Text to "sieve" the rows through (e.g. filter).
   * If empty string, all rows are returned.
   * What fields to check for occurence of the filter text
   * is determined by filterFields
   */
  filterText: string;
  /**
   * List of fields (from the model, as specified by Tmpl)
   * to use when filtering
   * @template Tmpl
   */
  filterFields: Array<keyof Tmpl>;
}

type FilteredDataGridProps<Tmpl> = Partial<CreateProps> &
  GridProps<Tmpl> &
  Partial<FilterProps<Tmpl>>;

/**
 * A table grid with filtrering possibilities, potentially with
 * ability to create new rows
 * @param props
 * @template Tmpl Data model (of the grid source) to use
 */
const FilteredDataGrid = <Tmpl extends GridRowModel>(
  props: FilteredDataGridProps<Tmpl>,
): JSX.Element => {
  const [filterText, setFilterText] = useState<string>('');
  const [sortProp, setSortProp] = useState<SortProps<Tmpl>>({
    ...{
      field: '',
    },
    ...props.sortBy,
  });

  const toSortModel = (from: SortProps<Tmpl>): GridSortModel => {
    return [
      {
        field: from.field as string,
        sort: from.order || 'asc',
      },
    ];
  };

  const toSortProp = (from: GridSortModel): SortProps<Tmpl> => {
    // NOTE: we don't support multi-column sort, by we could easily
    return {
      field: (from[0]?.field || '') as keyof Tmpl,
      order: from[0]?.sort,
    };
  };

  const handleCreateClick = (): void => {
    (props?.onCreateClick || Function())();
  };

  const getRowId = (row: GridRowModel): string => {
    return row[props.rowIdentifier || 'id'];
  };

  const handleRowClick = (params: GridRowParams): void => {
    (props?.onRowClick || Function())(params.row);
  };

  const handleCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
  ): void => {
    (props?.onCellClick || Function())(params.field, params.row, event);
  };

  const { filterFields, rows } = props;

  /**
   * NOTE: instead of doing the filtering this way, we should utilize
   * the built-in `filterModel` on the DataGrid itself, using
   * `contains` as operator
   */
  const filteredRows = useMemo(() => {
    if (filterFields && filterFields.length && filterText.length) {
      const lowerCaseFilterText = filterText.toLocaleLowerCase();
      return rows.filter((row) => {
        return filterFields.some((field) => {
          const pos = String(row[field])
            .toLocaleLowerCase()
            .indexOf(lowerCaseFilterText);
          return pos >= 0;
        });
      });
    } else {
      return rows;
    }
  }, [rows, filterText, filterFields]);

  /**
   * Extend all columns with a render callback, that pipes
   * their cell contents through a EmphasisString, e.g. whenever
   * we got a text to filter on, it will highlight any hits
   */
  const columns = useMemo(() => {
    return props.columns.reduce((agg, col) => {
      const cellRenderObj: Partial<GridColDef> = {
        renderCell: (params: GridCellParams) => (
          <EmphasisString string={params.value} emString={filterText} />
        ),
      };
      agg.push(Object.assign({}, col, cellRenderObj));
      return agg;
    }, [] as GridColDef[]);
  }, [props.columns, filterText]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Grid
        container
        sx={{
          gap: '12px',
          padding: '16px 16px 0 16px',
        }}
      >
        {!props.disableCreate && (
          <Grid item>
            <Button
              data-cy={props?.createButtonCy || ''}
              color="primary"
              startIcon={
                <Icon
                  color="primary"
                  style={{ paddingLeft: 5, fontSize: '24px' }}
                >
                  add_circle
                </Icon>
              }
              onClick={handleCreateClick}
              sx={{
                fontWeight: 500,
                padding: '6px 0px',
              }}
            >
              {props.label || T('Create')}
            </Button>
          </Grid>
        )}
        <Grid item flex={1}>
          <TextField
            data-cy="search-input"
            fullWidth
            size="small"
            placeholder={T('Enter search term...')}
            value={filterText}
            variant="filled"
            hiddenLabel
            onChange={(e) => setFilterText(e.target.value)}
            InputProps={{
              sx: {
                borderRadius: 1,
                height: 36,
                padding: 0,
                paddingRight: 1,
                fontSize: '14px',
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon color="disabled">search</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <DataGridPro
        sx={{
          border: '0 !important',
          '& .MuiDataGrid-columnHeader:first-child': {
            paddingLeft: 4,
          },
          '& .MuiDataGrid-columnHeader': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-cell': {
            outline: 'none !important',
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:first-child': {
            paddingLeft: 4,
          },
          '& .MuiDataGrid-row:hover': { backgroundColor: '#f3f1f7' },
        }}
        disableColumnMenu
        rowHeight={34}
        rows={filteredRows}
        columns={columns}
        getRowId={getRowId}
        isRowSelectable={() => false}
        onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        sortModel={toSortModel(sortProp)}
        initialState={{
          sorting: {
            sortModel: toSortModel(sortProp),
          },
        }}
        onSortModelChange={(model) => setSortProp(toSortProp(model))}
        localeText={getDataGridLocale()}
        {...props.DataGridProps}
      />
    </Box>
  );
};

export default FilteredDataGrid;
