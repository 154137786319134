import { AppBar, Button, Icon, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import StartPage from 'pages/startpage/StartPage';
import Admin from 'pages/admin/Admin';
import React, { useContext } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import SessionContext from 'SessionContext';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import useFeatureFlag from 'FeatureFlagHook';
import T from 'utils/translation';

const Header = styled('div')(({ theme }) => ({
  height: theme.spacing(10),
  width: '100%',
  borderBottom: '1px solid #eee',
  display: 'flex',
}));

const Logo = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1),
}));

const ButtonWrapper = styled('div')({
  display: 'flex',
  gap: 10,
  padding: 10,
});

const MyNavLink = styled(NavLink)<{ selected: boolean }>`
  min-width: 0px;
  width: 56px;
  height: 40px;
  background-color: ${(p) => (p.selected ? 'rgb(108, 81, 152)' : 'inherit')};
  &:hover {
    background-color: ${(p) => (p.selected ? 'rgb(108, 81, 152)' : 'inherit')};
    background: ${(p) => (p.selected ? 'rgb(108, 81, 152)' : '#f3f1f7')};
  }
`;

interface IPageRouterProps {
  logoUrl: string;
}

enum Page {
  Start = '/start',
  Admin = '/admin',
}

/**
 * Workaround for "<Link component={X}> causes console warning: "Invalid value for prop navigate..."
 * @see https://github.com/remix-run/react-router/issues/6962
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ButtonWithoutNavigate = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { navigate, ...rest } = props;
  return <Button {...rest} />;
};

const PageNavigation: React.FC<IPageRouterProps> = (props) => {
  const { logoUrl } = props;
  const sessionContext = useContext(SessionContext);
  const maySeeAdmin = useFeatureFlag('BB-412'); // Lock admin page behind feature flag BB-412 for now
  const readyToView = maySeeAdmin !== undefined;
  const location = useLocation();

  const path = '/' + location.pathname.split('/')[1];

  return readyToView ? (
    <>
      <AppBar color="inherit" position="sticky" elevation={1}>
        <Header>
          <Logo>
            <img
              src={logoUrl}
              alt="logo"
              style={{ maxWidth: '100%', height: 36 }}
            />
          </Logo>
          <div style={{ flex: 1 }} />
          <ButtonWrapper>
            <SessionContext.Consumer>
              {({ hasAtLeastRole }) =>
                hasAtLeastRole('admin') &&
                maySeeAdmin && (
                  <>
                    <Tooltip title={T('Salary central')}>
                      <div>
                        <MyNavLink
                          data-cy={'salary-central-button'}
                          to={Page.Start}
                          component={ButtonWithoutNavigate}
                          selected={path === Page.Start}
                        >
                          <Icon
                            sx={path === Page.Start ? { color: 'white' } : {}}
                          >
                            format_list_bulleted
                          </Icon>
                        </MyNavLink>
                      </div>
                    </Tooltip>

                    <Tooltip title={T('Administration')}>
                      <div>
                        <MyNavLink
                          data-cy={'administration-button'}
                          to={Page.Admin}
                          component={ButtonWithoutNavigate}
                          selected={path === Page.Admin}
                        >
                          <Icon
                            sx={path === Page.Admin ? { color: 'white' } : {}}
                          >
                            build
                          </Icon>
                        </MyNavLink>
                      </div>
                    </Tooltip>
                  </>
                )
              }
            </SessionContext.Consumer>
            <Tooltip title={T('Log out')}>
              <Button
                onClick={() => sessionContext.setActiveSession(false)}
                data-cy="logout-button"
                sx={{
                  minWidth: '0',
                  width: 56,
                  height: 40,
                  '&:hover': {
                    background: '#f3f1f7',
                  },
                }}
              >
                <Icon color="primary">logout</Icon>
              </Button>
            </Tooltip>
          </ButtonWrapper>
        </Header>
      </AppBar>
      {/**Update CookieConsent to dynamic solution when more pages are present */}
      <CookieConsent activeSession={true} page={Page.Start} />
      <Switch>
        <Route path={Page.Start}>
          <StartPage />
        </Route>
        <Route path={Page.Admin}>
          <SessionContext.Consumer>
            {({ hasAtLeastRole }) =>
              hasAtLeastRole('admin') && maySeeAdmin ? (
                <Admin />
              ) : (
                <Redirect to={Page.Start} />
              )
            }
          </SessionContext.Consumer>
        </Route>
        <Redirect to={Page.Start} />
      </Switch>
    </>
  ) : (
    <></>
  );
};

export default PageNavigation;
