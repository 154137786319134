import { GridLocaleText } from '@mui/x-data-grid-pro';

let lang = 'sv';

export const setTranslationVariant = (variant: string): void => {
  lang = variant;
};

const T = (key: string): string => {
  let ret = key;
  if (keys[key]) {
    if (keys[key][lang]) {
      ret = keys[key][lang];
    }
  }
  return ret;
};

interface IKeys {
  [K: string]: {
    [T: string]: string;
  };
}

const keys: IKeys = {
  'Active Licenses': {
    sv: 'Aktiva licenser',
  },
  'Admin': {
    sv: 'Administratör',
  },
  'Address to Millnet Närvaro': {
    sv: 'Adress till Millnet Närvaro',
  },
  'All': {
    sv: 'Alla',
  },
  'Allow': {
    sv: 'Tillåt',
  },
  'Are you sure you want to delete this bureau? This will delete all associated users. This action cannot be undone.':
    {
      sv: 'Är du säker på att du vill radera denna byrå? Detta kommer radera alla användare kopplade till byrån. Åtgärden kan inte ångras.',
    },
  'Are you sure you want to delete this client?': {
    sv: 'Är du säker på att du vill radera denna kund?',
  },
  'Are you sure you want to delete this user?': {
    sv: 'Är du säker på att du vill radera denna användare?',
  },
  'as suffix of Client-URL': {
    sv: 'som suffix av Kund-URL',
  },
  'Attested Timereports': {
    sv: 'Attesterade tidrapporter',
  },
  'Bureau': {
    sv: 'Byrå',
  },
  'Bureauid': {
    sv: 'Byråid',
  },
  'Bureaux': {
    sv: 'Byråer',
  },
  "Can see all of the bureau's clients.": {
    sv: 'Ser alla byråns kunder.',
  },
  'Can see clients which the user is connected to.': {
    sv: 'Ser kunder som användaren kopplas ihop med.',
  },
  'Cancel': {
    sv: 'Avbryt',
  },
  'Close': {
    sv: 'Stäng',
  },
  'Created': {
    sv: 'Skapad',
  },
  'Created by': {
    sv: 'Skapad av',
  },
  'Create bureau': {
    sv: 'Skapa byrå',
  },
  'Create client': {
    sv: 'Skapa kund',
  },
  'Create user': {
    sv: 'Skapa användare',
  },
  'Client': {
    sv: 'Kund',
  },
  'ClientURL': {
    sv: 'KundURL',
  },
  'Client URL is already taken': {
    sv: 'Adressen är redan tagen av annan kund',
  },
  'Clients': {
    sv: 'Kunder',
  },
  'Data from': {
    sv: 'Data från',
  },
  'Database name': {
    sv: 'Databasnamn',
  },
  'Delete bureau': {
    sv: 'Radera byrå',
  },
  'Delete client': {
    sv: 'Radera kund',
  },
  'Delete user': {
    sv: 'Radera användare',
  },
  'Designation': {
    sv: 'Benämning',
  },
  'Edit bureau': {
    sv: 'Redigera byrå',
  },
  'Edit client': {
    sv: 'Redigera kund',
  },
  'Edit user': {
    sv: 'Redigera användare',
  },
  'Enter password': {
    sv: 'Skriv lösenord',
  },
  'Enter search term...': {
    sv: 'Skriv sökord...',
  },
  'Enter username': {
    sv: 'Skriv användarnamn',
  },
  'Email': {
    sv: 'E-post',
  },
  'Expected Salary Reports': {
    sv: 'Förväntade löneunderlag',
  },
  'Expected Time Reports': {
    sv: 'Förväntade tidrapporter',
  },
  'ERROR': {
    sv: 'Misslyckades',
  },
  'Failed to delete bureau and all associated users. Error: ': {
    sv: 'Misslyckades att radera byrån och alla kopplade användare. Fel: ',
  },
  'Failed to delete user': {
    sv: 'Misslyckades att radera användaren',
  },
  'Filter data based on bureau': {
    sv: 'Filtrera data utifrån byrå',
  },
  'FINISHED': {
    sv: 'Skapad',
  },
  'Go to next page': {
    sv: 'Nästa sida',
  },
  'Go to previous page': {
    sv: 'Föregående sida',
  },
  'History': {
    sv: 'Historik',
  },
  'Instancename': {
    sv: 'Instansnamn',
  },
  'Instanceid': {
    sv: 'Instansid',
  },
  'Internal customer number': {
    sv: 'Internt Kundnummer',
  },
  'Latest Attest': {
    sv: 'Senast attesterad',
  },
  'Latest Attest by': {
    sv: 'Senast attesterad av',
  },
  'Latest Salary Run': {
    sv: 'Senaste lönekörning',
  },
  'Locked Timereports': {
    sv: 'Låsta tidrapporter',
  },
  'Login': {
    sv: 'Logga in',
  },
  'Log out': {
    sv: 'Logga ut',
  },
  'Organisation number': {
    sv: 'Organisationsnummer',
  },
  'Org. nr. already exists on bureau': {
    sv: 'Det finns redan en kund med det organisationsnumret hos den här byrån',
  },
  'more than': {
    sv: 'mer än',
  },
  'Millnet admin': {
    sv: 'Millnet Administratör',
  },
  'Name': {
    sv: 'Namn',
  },
  'No rows': {
    sv: 'Inga rader',
  },
  'Number of free licence': {
    sv: 'Antal gratislicenser',
  },
  'of': {
    sv: 'av',
  },
  'Of which Inactive Users': {
    sv: 'Varav inaktiva användare',
  },
  'Password': {
    en: 'Password',
    sv: 'Lösenord',
  },
  'Password must be at least 8 characters long': {
    sv: 'Lösenordet måste vara åtminstone 8 tecken långt',
  },
  'PENDING': {
    sv: 'Pågående',
  },
  'Please wait. If everything went correctly your new client can be found in the list within 5 minutes. Otherwise, please contact support@millnet.se.':
    {
      sv: 'Var god vänta! Går allt bra visas din kund i listan inom 5 minuter. Annars vänligen kontakta support@millnet.se.',
    },
  'Please wait. If everything went correctly the client will be deleted within 5 minutes. Otherwise, please contact support@millnet.se.':
    {
      sv: 'Var god vänta! Går allt bra kommer kunden tas bort inom 5 minuter. Annars vänligen kontakta support@millnet.se.',
    },
  'Read more': {
    sv: 'Läs mer',
  },
  'Remaining to Attest': {
    sv: 'Kvar att attestera',
  },
  'Remaining to Lock': {
    sv: 'Kvar att låsa',
  },
  'Role': {
    sv: 'Roll',
  },
  'rows selected': {
    sv: 'rader valda',
  },
  'Save': {
    sv: 'Spara',
  },
  'Salary central': {
    sv: 'Lönecentral',
  },
  'Select month': {
    sv: 'Välj månad',
  },
  'Successfully deleted bureau and all associated users': {
    sv: 'Lyckades radera byrån och alla kopplade användare',
  },
  'Successfully deleted user': {
    sv: 'Lyckades radera användaren',
  },
  'Summary': {
    sv: 'Summering',
  },
  'Sort': {
    sv: 'Sortera',
  },
  'Status': {
    sv: 'Status',
  },
  'Total rows': {
    sv: 'Antal rader',
  },
  'Update': {
    sv: 'Uppdatera',
  },
  'Updated at': {
    sv: 'Senast uppdaterad',
  },
  'Url': {
    sv: 'Url',
  },
  'Use bureau ID': {
    sv: 'Använd byråid',
  },
  'Users': {
    sv: 'Användare',
  },
  'User': {
    sv: 'Användare',
  },
  'User created': {
    sv: 'Användare skapad',
  },
  'User updated': {
    sv: 'Användare uppdaterad',
  },
  'Username/Email': {
    sv: 'Användarnamn/E-post',
  },
  'Username': {
    sv: 'Användarnamn',
  },
  'Yes': {
    sv: 'Ja',
  },
  'Warning': {
    sv: 'Varning',
  },
  'We are using cookies which makes this website work better.': {
    sv: 'Vi använder oss av cookies som gör att webbplatsen fungerar bättre.',
  },
  'Wrong username or password': {
    sv: 'Felaktigt användarnamn eller lösenord',
  },
};

export const getDataGridLocale = (): Partial<GridLocaleText> => ({
  noRowsLabel: T('No rows'),
  columnHeaderSortIconLabel: T('Sort'),
  MuiTablePagination: {
    backIconButtonProps: { title: T('Go to previous page') },
    nextIconButtonProps: { title: T('Go to next page') },
    labelDisplayedRows: (props) => {
      return `${props.from}–${props.to} ${T('of')} ${
        props.count !== -1 ? props.count : `${T('more than')} ${props.to}`
      }`;
    },
  },
  footerRowSelected: (count) => `${count} ${T('rows selected')}`,
  footerTotalRows: `${T('Total rows')}:`,
});

export default T;
