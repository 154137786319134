import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
  PaletteColor,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect } from 'react';
import ClientInfo from 'types/ClientInfo';
import T from 'utils/translation';
import EmphasisString from 'components/EmphasisString';
import StartPageFilter from './StartPageFilter';
import clientInfoApi from 'api/clientinfo';
import SessionContext from 'SessionContext';
import { ensureHttpsUrl } from 'utils/format';

const createClientInfo = (): ClientInfo => {
  return {
    ClientId: 0,
    ClientName: '',
    ClientUrl: '',
    LastAttestDate: '',
    LastAttestUser: '',
    LastPayrollCount: 0,
    LastPayrollDate: '',
    LastUpdated: '',
    LicenseCount: 0,
    PayrollExpCount: 0,
    RegMonth: '',
    TimeReportAttestCount: 0,
    TimeReportExpCount: 0,
    TimeReportInactiveExpCount: 0,
    TimeReportLockCount: 0,
    TimeReportLockedLeft: 0,
    TimeReportAttestedLeft: 0,
  };
};

interface Column {
  field: keyof ClientInfo;
  valueFn?: (item: ClientInfo) => string;
  headerName: string;
  width?: number;
  hideMobile: boolean;
  align?: TableCellProps['align'];
}

const columns: Column[] = [
  {
    field: 'ClientName',
    headerName: T('Client'),
    hideMobile: false,
  },
  {
    field: 'LicenseCount',
    headerName: T('Active Licenses'),
    hideMobile: true,
    align: 'right',
  },
  {
    field: 'TimeReportExpCount',
    headerName: T('Expected Time Reports'),
    hideMobile: true,
    align: 'right',
  },
  {
    field: 'TimeReportLockCount',
    headerName: T('Locked Timereports'),
    hideMobile: true,
    align: 'right',
  },
  {
    field: 'TimeReportAttestCount',
    headerName: T('Attested Timereports'),
    hideMobile: true,
    align: 'right',
  },
  {
    field: 'TimeReportLockedLeft',
    headerName: T('Remaining to Lock'),
    hideMobile: false,
    align: 'center',
    width: 70,
  },
  {
    field: 'TimeReportAttestedLeft',
    headerName: T('Remaining to Attest'),
    hideMobile: false,
    align: 'center',
    width: 70,
  },
  {
    field: 'LastAttestDate',
    headerName: T('Latest Attest'),
    hideMobile: true,
    align: 'right',
    width: 85,
  },
  {
    field: 'LastAttestUser',
    headerName: T('Latest Attest by'),
    hideMobile: true,
    align: 'left',
    width: 150,
  },
  {
    field: 'PayrollExpCount',
    headerName: T('Expected Salary Reports'),
    hideMobile: true,
    align: 'right',
    //width: 120,
  },
  {
    field: 'LastPayrollDate',
    headerName: T('Latest Salary Run'),
    hideMobile: true,
    align: 'right',
    width: 100,
  },
];

const columnFieldMap: { [Property in keyof ClientInfo]?: Column } = {};

columns.forEach((col) => {
  columnFieldMap[col.field] = col;
});

const getClientInfoComparator = (
  columnName: keyof ClientInfo,
): ((a: ClientInfo, b: ClientInfo) => number) => {
  return (a: ClientInfo, b: ClientInfo) => {
    if (a[columnName] === null && b[columnName] === null) return 0;
    if (a[columnName] === null && b[columnName] !== null) return -1;
    if (a[columnName] !== null && b[columnName] === null) return 1;
    if (typeof a[columnName] === 'string') {
      return ((a[columnName] as string) ?? '')
        .toLocaleLowerCase()
        .localeCompare(((b[columnName] as string) ?? '').toLocaleLowerCase());
    } else {
      return Math.sign((a[columnName] as number) - (b[columnName] as number));
    }
  };
};
const StartPage = (): JSX.Element => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [month, setMonth] = React.useState<Date>(new Date());
  const [filterText, setFilterText] = React.useState('');
  const [updatedTs, setUpdatedTs] = React.useState<string>('');
  const [dataArr, setDataArr] = React.useState<ClientInfo[]>([]);
  const [sortColumn, setSortColumn] =
    React.useState<keyof ClientInfo>('ClientName');
  const [sortDirr, setSortDirr] = React.useState<'asc' | 'desc'>('asc');
  const [bureauFilter, setBureauFilter] = React.useState<string>('');
  const sessionContext = useContext(SessionContext);

  const sortDataArr = (column: Column): void => {
    if (dataArr != null) {
      if (column.field === sortColumn) {
        setDataArr(dataArr.slice().reverse());
        setSortDirr(sortDirr === 'asc' ? 'desc' : 'asc');
      } else {
        const sorted = dataArr.slice();
        sorted.sort(getClientInfoComparator(column.field));
        setDataArr(sorted);
        setSortColumn(column.field);
        setSortDirr('asc');
      }
    }
  };

  useEffect(() => {
    //Load data
    clientInfoApi
      .get(month, bureauFilter)
      .then((res) => {
        res.sort(getClientInfoComparator(sortColumn));
        if (sortDirr === 'desc') {
          res.reverse();
        }
        setDataArr(res);
        let lastUpdated = '';
        res.forEach((ci: ClientInfo) => {
          lastUpdated =
            ci.LastUpdated && ci.LastUpdated?.localeCompare(lastUpdated) > 0
              ? ci.LastUpdated
              : lastUpdated;
        });
        setUpdatedTs(lastUpdated);
      })
      .catch((error) => {
        if (error.status === 401) {
          // Unauthorized, assume session expired.
          sessionContext.setActiveSession(false);
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      });
  }, [month, bureauFilter]);
  const filteredArr = dataArr?.filter((trData) => {
    return (
      filterText.length < 0 ||
      trData.ClientName.toLocaleLowerCase().indexOf(
        filterText.toLocaleLowerCase(),
      ) > -1
    );
  });
  const summedData = createClientInfo();
  if (filteredArr) {
    Object.assign(summedData, summarizeData(filteredArr));
  }
  return (
    <Root>
      <SessionContext.Consumer>
        {(session) => (
          <StartPageFilter
            setBureau={(bureau: string) => setBureauFilter(bureau)}
            session={session}
            {...{
              month,
              setMonth,
              filterText,
              setFilterText,
              updatedTs,
            }}
          />
        )}
      </SessionContext.Consumer>
      <Paper
        sx={{
          marginTop: theme.spacing(4),
          '& th:not(:last-child)': {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
          },
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
          },
        }}
      >
        <TableContainer style={{ maxWidth: '100%', maxHeight: '100%' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              flex: 1,
              [theme.breakpoints.down('sm')]: {
                tableLayout: 'fixed',
              },
            }}
          >
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => !smallScreen || !column.hideMobile)
                  .map((column) => {
                    return (
                      <TableCell
                        key={column.field}
                        sx={{
                          backgroundColor: '#fff',
                        }}
                        align={column.align}
                        width={!smallScreen ? column.width : undefined}
                      >
                        <TableSortLabel
                          active={column.field === sortColumn}
                          onClick={() => sortDataArr(column)}
                          direction={sortDirr}
                          style={{ display: 'flex' }}
                          sx={
                            column.align === 'right'
                              ? { icon: { position: 'absolute', right: -18 } }
                              : { icon: { position: 'absolute', left: -18 } }
                          }
                        >
                          <span style={{ flex: 1 }}>{column.headerName}</span>
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {[summedData, ...filteredArr].map((item, idx) => {
                let nameColContent;
                if (idx === 0) {
                  nameColContent = item.ClientName;
                } else if (smallScreen) {
                  nameColContent = (
                    <EmphasisString
                      string={item.ClientName}
                      emString={filterText}
                      style={{ fontWeight: 500 }}
                    />
                  );
                } else {
                  nameColContent = (
                    <Tooltip title={ensureHttpsUrl(item.ClientUrl)}>
                      <ClientLink
                        href={
                          (!smallScreen && ensureHttpsUrl(item.ClientUrl)) ||
                          undefined
                        }
                        target="_blank"
                      >
                        <EmphasisString
                          string={item.ClientName}
                          emString={filterText}
                        />
                      </ClientLink>
                    </Tooltip>
                  );
                }
                return (
                  <TableRow
                    key={item.ClientId}
                    sx={
                      idx === 0
                        ? { backgroundColor: theme.palette.grey[100] }
                        : {}
                    }
                  >
                    <TableCell
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          width: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    >
                      {nameColContent}
                    </TableCell>
                    {!smallScreen && (
                      <>
                        <TableCell align="right">{item.LicenseCount}</TableCell>
                        <TableCell align="right">
                          {item.TimeReportExpCount}
                        </TableCell>
                        <TableCell align="right">
                          {item.TimeReportLockCount}
                        </TableCell>
                        <TableCell align="right">
                          {item.TimeReportAttestCount}
                        </TableCell>
                      </>
                    )}
                    <TableCell
                      align="center"
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          width: '25%',
                        },
                        [theme.breakpoints.up('md')]: {
                          ['& .MuiTableSortLabel-icon']: {
                            position: 'absolute',
                            right: -18,
                          },
                        },
                      }}
                    >
                      <ColoredSpan value={item.TimeReportLockedLeft} />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          width: '25%',
                        },
                        [theme.breakpoints.up('md')]: {
                          ['& .MuiTableSortLabel-icon']: {
                            position: 'absolute',
                            right: -18,
                          },
                        },
                      }}
                    >
                      <ColoredSpan value={item.TimeReportAttestedLeft} />
                    </TableCell>
                    {!smallScreen && (
                      <>
                        <TableCell align="right">
                          {item.LastAttestDate}
                        </TableCell>
                        <TableCell align="left">
                          {item.LastAttestUser}
                        </TableCell>
                        <TableCell align="right">
                          {item.PayrollExpCount}
                        </TableCell>
                        <TableCell align="right">
                          {item.LastPayrollDate}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter>
          <EmText>{filteredArr?.length ?? 0}</EmText> {T('of')}{' '}
          <EmText>{dataArr?.length ?? 0}</EmText> {T('Clients').toLowerCase()}
        </TableFooter>
      </Paper>
    </Root>
  );
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f8f8f8',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const ClientLink = styled('a')(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
  fontWeight: 500,
  color: 'inherit',
  margin: theme.spacing(-1),
  padding: theme.spacing(1),
  borderRadius: 3,
  '&:hover': {
    backgroundColor: '#f6f6f6',
  },
}));

const TableFooter = styled('div')(({ theme }) => ({
  textAlign: 'right',
  padding: theme.spacing(2),
  paddingRight: theme.spacing(4),
}));

const EmText = styled('span')({
  fontWeight: 500,
});

interface ColoredSpanProps {
  value: number;
}
const ColoredSpan = ({ value }: ColoredSpanProps): JSX.Element => {
  const theme = useTheme();
  const paletteColor: PaletteColor =
    value > 0 ? theme.palette.error : theme.palette.success;
  return (
    <span
      style={{
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: paletteColor.main,
        color: paletteColor.contrastText,
        padding: 2,
        width: 50,
        borderRadius: 3,
      }}
    >
      {value}
    </span>
  );
};

const summarizeData = (dataArr: ClientInfo[]): ClientInfo => {
  const sum = createClientInfo();
  sum.ClientName = T('Summary');
  dataArr.forEach((item) => {
    sum.LicenseCount += item.LicenseCount ?? 0;
    sum.TimeReportExpCount += item.TimeReportExpCount;
    sum.TimeReportInactiveExpCount += item.TimeReportInactiveExpCount;
    sum.TimeReportLockCount += item.TimeReportLockCount;
    sum.TimeReportAttestCount += item.TimeReportAttestCount;
    sum.TimeReportLockedLeft += item.TimeReportLockedLeft;
    sum.TimeReportAttestedLeft += item.TimeReportAttestedLeft;
    if (item.LastAttestDate != null && sum.LastAttestDate != null) {
      sum.LastAttestDate =
        sum.LastAttestDate.localeCompare(item.LastAttestDate) < 0
          ? sum.LastAttestDate
          : item.LastAttestDate;
    }
    sum.PayrollExpCount += item.PayrollExpCount;
    if (item.LastPayrollDate != null && sum.LastPayrollDate) {
      sum.LastPayrollDate =
        sum.LastPayrollDate.localeCompare(item.LastPayrollDate) < 0
          ? sum.LastPayrollDate
          : item.LastPayrollDate;
    }
  });
  return sum;
};

export default StartPage;
