import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef, MuiEvent } from '@mui/x-data-grid-pro';
import FilteredDataGrid from 'components/FilteredDataGrid';
import T from 'utils/translation';
import clientApi from 'api/client';
import pidApi from 'api/pid';
import picApi from 'api/pic';
import Client from 'types/Client';
import bureauApi from 'api/bureau';
import Bureau from 'types/Bureau';
import SessionContext from 'SessionContext';
import useNotification from 'hooks/useNotification';
import { ensureHttpsUrl } from 'utils/format';
import { Delete } from '@mui/icons-material';
import useFeatureFlag from 'FeatureFlagHook';

const Clients = (): JSX.Element => {
  const sessionContext = useContext(SessionContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogUpdateMode, setDialogUpdateMode] = useState(false);
  const [dialogHeaderText, setDialogHeaderText] = useState(T('Create client'));
  const theme = useTheme();
  const [dataArr, setDataArr] = React.useState<Client[]>([]);
  const [bureauArr, setBureauArr] = React.useState<Bureau[]>([]);
  const [dialogClientName, setDialogClientName] = useState('');
  const [dialogOrganisationNumber, setDialogOrganisationNumber] = useState('');
  const orgnrRegexp = /^[0-9]{6}-[0-9]{4}$/gm;
  const [dialogInstanceUrlPart, setDialogInstanceUrlPart] = useState('');
  const [dialogClientId, setDialogClientId] = useState(-1);
  const [dialogClientUrl, setDialogClientUrl] = useState('');
  const [dialogBureauId, setDialogBureauId] = useState(
    sessionContext.role == 'admin' ? sessionContext.bureauid : '',
  );
  const [dialogValueChanged, setDialogValueChanged] = useState(false);
  const [dialogInputValidity, setDialogInputValidity] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const mayDeleteClient = useFeatureFlag('BB-46'); // Lock deletion of clients behind feature flag BB-46 for now
  const { addNotification } = useNotification();

  const columns: GridColDef[] = [
    {
      field: 'clientname',
      headerName: T('Client'),
      flex: 1,
    },
    {
      field: 'bureauid',
      headerName: T('Bureau'),
      flex: 1,
      valueGetter: (value) => {
        return bureauArr.find(
          (bureau) => bureau.bureauid === value.row.bureauid,
        )?.bureauname;
      },
    },
    {
      field: 'orgnr',
      headerName: T('Organisation number'),
      flex: 1,
    },
    {
      field: 'clienturl',
      headerName: T('ClientURL'),
      flex: 1,
      valueGetter: (value) => ensureHttpsUrl(value.row.clienturl),
    },
  ];

  useEffect(() => {
    let validity = true;

    if (
      dialogClientName.length == 0 ||
      (!dialogUpdateMode && dialogOrganisationNumber.length == 0) ||
      (!dialogUpdateMode && dialogInstanceUrlPart.length < 3) ||
      (!dialogUpdateMode && dialogBureauId.length == 0) ||
      !dialogValueChanged
    ) {
      validity = false;
    }
    setDialogInputValidity(validity);
  });

  useEffect(() => {
    updateTable();
  }, []);

  const handleCreate = () => {
    setDialogHeaderText(T('Create client'));
    setDialogUpdateMode(false);
    setDialogOpen(true);
  };

  const handleRowClick = (row: Client) => {
    setDialogClientName(row.clientname);
    setDialogOrganisationNumber(row.orgnr);
    setDialogClientId(row.clientid ? row.clientid : -1);
    setDialogClientUrl(row.clienturl);
    setDialogBureauId(row.bureauid);
    setDialogValueChanged(false);
    setDialogHeaderText(T('Edit client'));
    setDialogUpdateMode(true);
    setDialogOpen(true);
  };

  const handleCellClick = (
    field: string,
    row: Client,
    event: MuiEvent<React.MouseEvent>,
  ) => {
    if (field === 'clienturl' && row.clienturl) {
      event.preventDefault();
      event.stopPropagation();
      window.open(
        ensureHttpsUrl(row.clienturl),
        '_blank',
        'noopener noreferrer',
      );
    }
  };

  const updateTable = () => {
    clientApi.get().then((res) => {
      setDataArr(res);
    });
    bureauApi.get().then((res) => {
      setBureauArr(res);
    });
  };

  const saveAction = () =>
    new Promise<void>((resolve) => {
      picApi
        .post({
          instancename: dialogClientName,
          clienturlpart: dialogInstanceUrlPart,
          bureauid: dialogBureauId,
          orgnr: dialogOrganisationNumber,
        })
        .then(() => {
          addNotification(
            T(
              'Please wait. If everything went correctly your new client can be found in the list within 5 minutes. Otherwise, please contact support@millnet.se.',
            ),
            'info',
          );
          updateTable();
          resolve();
        })
        .catch((err) => {
          addNotification(T(err.text), 'error');
        });
    });

  const updateAction = () => {
    clientApi
      .put(dialogClientId, {
        clientname: dialogClientName,
        bureauid: dialogBureauId,
        clienturl: dialogClientUrl,
        orgnr: dialogOrganisationNumber,
      })
      .then(() => {
        updateTable();
      });
  };

  const cleanAndClose = () => {
    setDialogClientName('');
    setDialogOrganisationNumber('');
    setDialogInstanceUrlPart('');
    setDialogBureauId('');
    setDialogOpen(false);
  };

  const saveAndClose = () => {
    // Only clean and close if the save was successful; this is to
    // prevent the dialog from closing if the user made an error
    saveAction().then(() => cleanAndClose());
  };

  const updateAndClose = () => {
    updateAction();
    cleanAndClose();
  };

  const handleDelete = () => {
    pidApi.post({
      clientid: dialogClientId,
      instancename: dialogClientName,
      orgnr: dialogOrganisationNumber,
      bureauid: dialogBureauId,
    });
    cleanAndClose();
  };

  const onConfirmDeletion = () => {
    handleDelete();
    setConfirmationDialogOpen(false);
    addNotification(
      T(
        'Please wait. If everything went correctly the client will be deleted within 5 minutes. Otherwise, please contact support@millnet.se.',
      ),
      'info',
    );
  };

  return (
    <>
      <FilteredDataGrid<Client>
        label={T('Create client')}
        onCreateClick={handleCreate}
        rows={dataArr}
        columns={columns}
        rowIdentifier="clientid"
        onRowClick={handleRowClick}
        onCellClick={handleCellClick}
        filterFields={['clientname']}
        sortBy={{
          field: 'clientname',
        }}
        createButtonCy="create-client-button"
      />

      <Dialog
        open={dialogOpen}
        onClose={cleanAndClose}
        onBackdropClick={cleanAndClose}
        fullWidth
      >
        <DialogTitle>{dialogHeaderText}</DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            }}
          >
            <TextField
              data-cy="name-input"
              label={T('Name')}
              value={dialogClientName}
              onChange={(e) => {
                setDialogClientName(e.target.value);
                setDialogValueChanged(true);
              }}
              required={true}
              type="text"
              fullWidth
            />
            {!dialogUpdateMode && (
              <TextField
                data-cy="organisation-number-input"
                label={T('Organisation number')}
                value={dialogOrganisationNumber}
                onChange={(e) => {
                  setDialogOrganisationNumber(e.target.value);
                  setDialogValueChanged(true);
                }}
                error={
                  !(dialogOrganisationNumber.length == 0) &&
                  !orgnrRegexp.test(dialogOrganisationNumber)
                }
                helperText="xxxxxx-xxxx"
                required={true}
                type="text"
                fullWidth
              />
            )}
            {!dialogUpdateMode && (
              <TextField
                data-cy="address-input"
                label={T('Address to Millnet Närvaro')}
                value={dialogInstanceUrlPart}
                onChange={(e) => {
                  const urlRegexp = /^[a-z0-9-]{1,20}$/;
                  if (e.target.value === '' || urlRegexp.test(e.target.value)) {
                    setDialogInstanceUrlPart(e.target.value);
                    setDialogValueChanged(true);
                  }
                }}
                error={
                  !(dialogInstanceUrlPart.length == 0) &&
                  dialogInstanceUrlPart.length < 3
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {bureauArr.find(
                        (bureau) => bureau.bureauid === dialogBureauId,
                      )?.usesuffixforclient
                        ? `.${dialogBureauId}.millnet.cloud`
                        : '.millnet.cloud'}
                    </InputAdornment>
                  ),
                }}
                required={!dialogUpdateMode}
                type="text"
                fullWidth
              />
            )}
            {sessionContext.role == 'superadmin' && (
              <TextField
                data-cy="bureaux-dropdown"
                label={T('Bureau')}
                value={dialogBureauId}
                onChange={(e) => {
                  setDialogBureauId(e.target.value);
                  setDialogValueChanged(true);
                }}
                required={true}
                select
                children={bureauArr.map((bureau) => (
                  <MenuItem
                    key={bureau.bureauid}
                    value={bureau.bureauid}
                    data-cy="bureaux-item-dropdown"
                  >
                    {bureau.bureauname}
                  </MenuItem>
                ))}
                type="text"
                fullWidth
              />
            )}
          </div>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {mayDeleteClient && dialogUpdateMode && (
            <Tooltip title={T('Delete client')}>
              <IconButton
                style={{ margin: theme.spacing(2) }}
                onClick={() => setConfirmationDialogOpen(true)}
                data-cy="delete-client-button"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ flex: '1 0 0' }} />
          <DialogActions>
            <Button
              data-cy="save-update-button"
              onClick={dialogUpdateMode ? updateAndClose : saveAndClose}
              color="primary"
              disabled={!dialogInputValidity}
            >
              {dialogUpdateMode ? T('Update') : T('Save')}
            </Button>
            <Button
              data-cy="close-button"
              onClick={cleanAndClose}
              color="primary"
            >
              {T('Close')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onBackdropClick={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>{T('Warning')}</DialogTitle>
        <DialogContent>
          {T('Are you sure you want to delete this client?')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onConfirmDeletion();
            }}
            color="primary"
            data-cy="confirm-deletion-button"
          >
            {T('Yes')}
          </Button>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="primary"
          >
            {T('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Clients;
