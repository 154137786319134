import React, { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Switch,
  FormControlLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import FilteredDataGrid from 'components/FilteredDataGrid';
import T from 'utils/translation';
import Bureau from 'types/Bureau';
import bureauApi from 'api/bureau';
import { Delete } from '@mui/icons-material';
import useNotification from 'hooks/useNotification';

const Bureaux = (): JSX.Element => {
  const [bureauDialogOpen, setBureauDialogOpen] = useState(false);
  const [dialogUpdateMode, setDialogUpdateMode] = useState(false);
  const [dialogHeaderText, setDialogHeaderText] = useState(T('Create bureau'));
  const theme = useTheme();
  const [dataArr, setDataArr] = React.useState<Bureau[]>([]);
  const [dialogInputValidity, setDialogInputValidity] = useState(false);
  const [dialogUseSuffixForClient, setDialogUseSuffixForClient] =
    useState(true);
  const [dialogBureauName, setDialogBureauName] = useState('');
  const [dialogBureauId, setDialogBureauId] = useState('');
  const [dialogInternalCustomerNr, setDialogInternalCustomerNr] = useState('');
  const [dialogValueChanged, setDialogValueChanged] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { addNotification } = useNotification();

  const columns: GridColDef[] = [
    {
      field: 'bureauname',
      headerName: T('Bureau'),
      flex: 1,
    },
    {
      field: 'bureauid',
      headerName: T('Byråid'),
      flex: 1,
    },
    {
      field: 'internalcustomernr',
      headerName: T('Internal customer number'),
      flex: 1,
    },
  ];

  useEffect(() => {
    updateTable();
  }, []);

  useEffect(() => {
    let validity = true;

    if (
      dialogBureauName.length == 0 ||
      dialogInternalCustomerNr.length == 0 ||
      (!dialogUpdateMode && dialogBureauId.length == 0) ||
      !dialogValueChanged
    ) {
      validity = false;
    }
    setDialogInputValidity(validity);
  });

  const handleCreate = () => {
    setDialogHeaderText(T('Create bureau'));
    setDialogUpdateMode(false);
    setBureauDialogOpen(true);
  };

  const handleRowClick = (row: Bureau) => {
    setDialogBureauName(row.bureauname);
    setDialogBureauId(row.bureauid ? row.bureauid : '');
    setDialogInternalCustomerNr(row.internalcustomernr);
    setDialogUseSuffixForClient(row.usesuffixforclient);
    setDialogValueChanged(false);
    setDialogHeaderText(T('Edit bureau'));
    setDialogUpdateMode(true);
    setBureauDialogOpen(true);
  };

  const updateTable = () => {
    bureauApi.get().then((res) => {
      setDataArr(res);
    });
  };

  const saveAction = () => {
    bureauApi
      .post({
        bureauid: dialogBureauId,
        bureauname: dialogBureauName,
        internalcustomernr: dialogInternalCustomerNr,
        usesuffixforclient: dialogUseSuffixForClient,
      })
      .then(() => {
        updateTable();
      });
  };

  const updateAction = () => {
    bureauApi
      .put(dialogBureauId, {
        bureauname: dialogBureauName,
        internalcustomernr: dialogInternalCustomerNr,
        usesuffixforclient: dialogUseSuffixForClient,
      })
      .then(() => {
        updateTable();
      });
  };

  const cleanAndClose = () => {
    setDialogBureauName('');
    setDialogBureauId('');
    setDialogInternalCustomerNr('');
    setBureauDialogOpen(false);
  };

  const saveAndClose = () => {
    saveAction();
    cleanAndClose();
  };

  const updateAndClose = () => {
    updateAction();
    cleanAndClose();
  };

  const handleDelete = () => {
    bureauApi
      .delete(dialogBureauId)
      .then(() => {
        updateTable();
        setConfirmationDialogOpen(false);
        addNotification(
          T('Successfully deleted bureau and all associated users'),
          'success',
        );
        cleanAndClose();
      })
      .catch((response) => {
        addNotification(
          T('Failed to delete bureau and all associated users. Error: ') +
            response.message,
          'error',
        );
      });
  };

  return (
    <>
      <FilteredDataGrid<Bureau>
        label={T('Create bureau')}
        onCreateClick={handleCreate}
        rows={dataArr}
        columns={columns}
        rowIdentifier="bureauid"
        onRowClick={handleRowClick}
        filterFields={['bureauname']}
        sortBy={{
          field: 'bureauname',
        }}
        createButtonCy="create-bureau-button"
      />
      <Dialog
        open={bureauDialogOpen}
        onClose={cleanAndClose}
        onBackdropClick={cleanAndClose}
        fullWidth
      >
        <DialogTitle>{dialogHeaderText}</DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            }}
          >
            <TextField
              data-cy="name-input"
              label={T('Name')}
              value={dialogBureauName}
              onChange={(e) => {
                setDialogBureauName(e.target.value);
                setDialogValueChanged(true);
              }}
              required={true}
              type="text"
              fullWidth
            />
            {!dialogUpdateMode && (
              <TextField
                data-cy="bureauid-input"
                label={T('Bureauid')}
                value={dialogBureauId}
                onChange={(e) => {
                  const re = /^[a-z]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setDialogBureauId(e.target.value);
                    setDialogValueChanged(true);
                  }
                }}
                required={true}
                type="text"
                fullWidth
              />
            )}
            <TextField
              data-cy="internal-customer-number-input"
              label={T('Internal customer number')}
              value={dialogInternalCustomerNr}
              onChange={(e) => {
                const re = /^\d+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setDialogInternalCustomerNr(e.target.value);
                  setDialogValueChanged(true);
                }
              }}
              required={true}
              type="text"
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  data-cy="switch-button"
                  checked={dialogUseSuffixForClient}
                  onChange={(e) => {
                    setDialogUseSuffixForClient(e.target.checked);
                    setDialogValueChanged(true);
                  }}
                />
              }
              label={
                T('Use bureau ID') +
                ` (${dialogBureauId}) ` +
                T('as suffix of Client-URL')
              }
              labelPlacement="start"
            />
          </div>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {dialogUpdateMode && (
            <Tooltip title={T('Delete bureau')}>
              <IconButton
                style={{ margin: theme.spacing(2) }}
                onClick={() => setConfirmationDialogOpen(true)}
                data-cy="delete-bureau-button"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ flex: '1 0 0' }} />
          <DialogActions>
            <Button
              data-cy="save-update-button"
              onClick={dialogUpdateMode ? updateAndClose : saveAndClose}
              color="primary"
              disabled={!dialogInputValidity}
            >
              {dialogUpdateMode ? T('Update') : T('Save')}
            </Button>
            <Button
              data-cy="close-button"
              onClick={cleanAndClose}
              color="primary"
            >
              {T('Close')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onBackdropClick={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>{T('Warning')}</DialogTitle>
        <DialogContent>
          {T(
            'Are you sure you want to delete this bureau? This will delete all associated users. This action cannot be undone.',
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
            }}
            color="primary"
            data-cy="confirm-deletion-button"
          >
            {T('Yes')}
          </Button>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="primary"
          >
            {T('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Bureaux;
