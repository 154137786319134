import { api } from './api';
import User from 'types/User';

const user = {
  url: 'users',
  get: (): Promise<User[]> => api.getJson(user.url),
  put: (body: User, userid: number): Promise<User> => {
    return api.put(`${user.url}/${userid}`, body);
  },
  post: (body: User): Promise<User> => {
    return api.postJson(user.url, body);
  },
  delete: (userid: number, bureauid: string): Promise<void> => {
    return api.delete(`${user.url}/${userid}`, { bureauid: bureauid });
  },
};
export default user;
