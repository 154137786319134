/* eslint-disable @typescript-eslint/no-explicit-any */
import wretch from 'wretch';

//In DEVELOPMENT connects with dev setupProxy.js and dotenv

const baseUrl = `sc-server/api/v1/`;

interface IApi {
  baseUrl: string;
  get: (url: string) => Promise<any>;
  getJson: (url: string) => Promise<any>;
  post: (url: string, body: any) => Promise<any>;
  postJson: (url: string, body: any) => Promise<any>;
  postFormUrl: (url: string, body: any) => Promise<any>;
  put: (url: string, body: any) => Promise<any>;
  delete: (url: string, body: any) => Promise<any>;
}

// TODO: Handle 401, for session expired. Send to login page
const api: IApi = {
  baseUrl,
  get: (url) =>
    wretch(baseUrl + url)
      .get()
      .res(),
  getJson: (url) =>
    wretch(baseUrl + url)
      .get()
      .json(),
  post: (url, body) =>
    wretch(baseUrl + url)
      .post(body)
      .res(),
  postJson: (url, body) =>
    wretch(baseUrl + url)
      .post(body)
      .json(),
  postFormUrl: (url, body) =>
    wretch(baseUrl + url)
      .formUrl(body)
      .post()
      .res(),
  put: (url, body) =>
    wretch(baseUrl + url)
      .put(body)
      .json(),
  delete: (url, body) =>
    wretch(baseUrl + url)
      .delete(body)
      .res(),
};

export { api };
