import { api } from './api';
import { PIC } from 'types/PIC';
import { createPIC } from 'types/PIC';

const pic = {
  url: 'pics',
  get: (): Promise<PIC[]> =>
    api
      .getJson(pic.url)
      .then((rows) =>
        rows.map((d: PIC) => ({ ...d, created: new Date(d.created) })),
      ),
  post: (body: createPIC): Promise<PIC> => {
    return api.postJson(pic.url, body);
  },
};

export default pic;
