import { api } from './api';
import Bureau from 'types/Bureau';
import User from 'types/User';
import Client from 'types/Client';

const bureau = {
  url: 'bureaus',
  get: (): Promise<Bureau[]> => api.getJson(bureau.url),
  getById: (bureauid: string): Promise<Bureau> =>
    api.getJson(`${bureau.url}/${bureauid}`),
  getUsersByBureauId: (bureauid: string): Promise<User[]> =>
    api.getJson(`${bureau.url}/${bureauid}/users`),
  getClientsByBureauId: (bureauid: string): Promise<Client[]> =>
    api.getJson(`${bureau.url}/${bureauid}/clients`),
  put: (id: string, body: Bureau): Promise<string> => {
    return api.put(`${bureau.url}/${id}`, body);
  },
  post: (body: Bureau): Promise<string> => {
    return api.postJson(bureau.url, body);
  },
  delete: (id: string): Promise<void> => {
    return api.delete(`${bureau.url}/${id}`, {});
  },
};
export default bureau;
