import React, { useState, useEffect, useContext } from 'react';
import {
  TextField,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  MenuItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import FilteredDataGrid from 'components/FilteredDataGrid';
import T from 'utils/translation';
import userApi from 'api/user';
import User from 'types/User';
import bureauApi from 'api/bureau';
import Bureau from 'types/Bureau';
import SessionContext from 'SessionContext';
import useNotification from 'hooks/useNotification';
import { Delete } from '@mui/icons-material';

const Users = (): JSX.Element => {
  const sessionContext = useContext(SessionContext);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [dialogUpdateMode, setDialogUpdateMode] = useState(false);
  const [dialogHeaderText, setDialogHeaderText] = useState(T('Create user'));
  const theme = useTheme();
  const [dataArr, setDataArr] = React.useState<User[]>([]);
  const [bureauArr, setBureauArr] = React.useState<Bureau[]>([]);
  const [dialogInputValidity, setDialogInputValidity] = useState(false);
  const [dialogRole, setDialogRole] = useState('admin');

  const [dialogEmail, setDialogEmail] = useState('');
  const [dialogFullname, setDialogFullname] = useState('');
  const [dialogBureauId, setDialogBureauId] = useState(
    sessionContext.role == 'admin' ? sessionContext.bureauid : '',
  );
  const [dialogPassword, setDialogPassword] = useState('');
  const [dialogUserId, setDialogUserId] = useState(-1);
  const [dialogValueChanged, setDialogValueChanged] = useState(false);
  const { addNotification } = useNotification();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const userRoles: {
    label: string;
    value: string;
    tooltip?: string;
  }[] = [
    {
      label: T('User'),
      value: 'user',
      tooltip: 'Can see clients which the user is connected to.',
    },
    {
      label: T('Admin'),
      value: 'admin',
      tooltip: "Can see all of the bureau's clients.",
    },
    {
      label: T('Millnet admin'),
      value: 'superadmin',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'fullname',
      headerName: T('Name'),
      flex: 1,
    },
    {
      field: 'bureauid',
      headerName: T('Bureau'),
      flex: 1,
      valueGetter: (value) => {
        return bureauArr.find(
          (bureau) => bureau.bureauid === value.row.bureauid,
        )?.bureauname;
      },
    },
    {
      field: 'email',
      headerName: T('Email'),
      flex: 1,
    },
    {
      field: 'role',
      headerName: T('Role'),
      flex: 1,
      valueGetter: (value) => {
        return userRoles.find((role) => role.value === value.row.role)?.label;
      },
    },
  ];

  useEffect(() => {
    updateTable();
    bureauApi.get().then((res) => {
      setBureauArr(res);
    });
  }, []);

  useEffect(() => {
    let validity = true;

    if (
      dialogRole.length == 0 ||
      dialogEmail.length == 0 ||
      dialogFullname.length == 0 ||
      dialogBureauId.length == 0 ||
      (!dialogUpdateMode && dialogPassword.length == 0) ||
      !dialogValueChanged
    ) {
      validity = false;
    }
    setDialogInputValidity(validity);
  });

  const handleNewUser = () => {
    setDialogHeaderText(T('Create user'));
    setDialogUpdateMode(false);
    setUserDialogOpen(true);
  };

  const handleRowClick = (row: User) => {
    setDialogRole(row.role);
    setDialogEmail(row.email);
    setDialogFullname(row.fullname);
    setDialogBureauId(row.bureauid);
    setDialogUserId(row.userid ? row.userid : -1);
    setDialogValueChanged(false);
    setDialogHeaderText(T('Edit user'));
    setDialogUpdateMode(true);
    setUserDialogOpen(true);
  };

  const updateTable = () => {
    userApi.get().then((res) => {
      setDataArr(res);
    });
  };

  const saveAction = () =>
    new Promise<void>((resolve) => {
      userApi
        .post({
          role: dialogRole,
          email: dialogEmail,
          fullname: dialogFullname,
          bureauid: dialogBureauId,
          password: dialogPassword,
        })
        .then(() => {
          addNotification(T('User created'));
          updateTable();
          resolve();
        })
        .catch((err) => {
          addNotification(T(err.text), 'error');
        });
    });

  const updateAction = () =>
    new Promise<void>((resolve) => {
      const userPutObject: User = {
        role: dialogRole,
        email: dialogEmail,
        fullname: dialogFullname,
        bureauid: dialogBureauId,
      };
      if (dialogPassword != '') {
        userPutObject.password = dialogPassword;
      }

      userApi
        .put(userPutObject, dialogUserId)
        .then(() => {
          updateTable();
          addNotification(T('User updated'));
          resolve();
        })
        .catch((err) => {
          addNotification(T(err.text), 'error');
        });
    });

  const cleanAndClose = () => {
    setDialogRole('');
    setDialogEmail('');
    setDialogFullname('');
    setDialogBureauId('');
    setDialogPassword('');
    setUserDialogOpen(false);
  };

  const saveAndClose = () => {
    // Only clean and close if the save was successful; this is to
    // prevent the dialog from closing if the user made an error
    saveAction().then(() => cleanAndClose());
  };

  const updateAndClose = () => {
    // Only clean and close if the save was successful; this is to
    // prevent the dialog from closing if the user made an error
    updateAction().then(() => cleanAndClose());
  };

  const handleDelete = () => {
    userApi
      .delete(dialogUserId, dialogBureauId)
      .then(() => {
        updateTable();
        setConfirmationDialogOpen(false);
        addNotification(T('Successfully deleted user'), 'success');
        cleanAndClose();
      })
      .catch(() => {
        addNotification(T('Failed to delete user'), 'error');
      });
  };

  return (
    <>
      <FilteredDataGrid<User>
        label={T('Create user')}
        onCreateClick={handleNewUser}
        rows={dataArr}
        columns={columns}
        rowIdentifier="email"
        onRowClick={handleRowClick}
        filterFields={['email', 'fullname']}
        sortBy={{
          field: 'fullname',
        }}
        createButtonCy="create-user-button"
      />
      <Dialog
        open={userDialogOpen}
        onClose={cleanAndClose}
        onBackdropClick={cleanAndClose}
        fullWidth
      >
        <DialogTitle>{dialogHeaderText}</DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(4),
            }}
          >
            <TextField
              data-cy="name-input"
              label={T('Name')}
              value={dialogFullname}
              onChange={(e) => {
                setDialogFullname(e.target.value);
                setDialogValueChanged(true);
              }}
              required={true}
              type="text"
              fullWidth
            />
            <TextField
              data-cy="username-email-input"
              label={T('Username/Email')}
              value={dialogEmail}
              onChange={(e) => {
                setDialogEmail(e.target.value);
                setDialogValueChanged(true);
              }}
              required={true}
              type="text"
              fullWidth
            />
            <TextField
              data-cy="password-input"
              label={T('Password')}
              value={dialogPassword}
              onChange={(e) => {
                setDialogPassword(e.target.value);
                setDialogValueChanged(true);
              }}
              required={!dialogUpdateMode}
              type="password"
              fullWidth
            />
            {sessionContext.role == 'superadmin' && (
              <TextField
                data-cy="role-dropdown"
                label={T('Role')}
                value={dialogRole}
                onChange={(e) => {
                  setDialogRole(e.target.value);
                  setDialogValueChanged(true);
                }}
                required={true}
                select
                children={userRoles.map(
                  (role) =>
                    role.value != 'superadmin' && (
                      <MenuItem
                        key={role.value}
                        value={role.value}
                        data-cy="role-item-dropdown"
                      >
                        <Tooltip
                          data-cy="role-hover"
                          title={T(role.tooltip || '')}
                        >
                          <span
                            style={{ display: 'inline-block', width: '100%' }}
                          >
                            {role.label}
                          </span>
                        </Tooltip>
                      </MenuItem>
                    ),
                )}
                type="text"
                fullWidth
              />
            )}
            {sessionContext.role == 'superadmin' && (
              <TextField
                data-cy="bureaux-dropdown"
                label={T('Bureau')}
                value={dialogBureauId}
                onChange={(e) => {
                  setDialogBureauId(e.target.value);
                  setDialogValueChanged(true);
                }}
                required={true}
                select
                children={bureauArr.map((bureau) => (
                  <MenuItem
                    key={bureau.bureauid}
                    value={bureau.bureauid}
                    data-cy="bureaux-item-dropdown"
                  >
                    {bureau.bureauname}
                  </MenuItem>
                ))}
                type="text"
                fullWidth
              />
            )}
          </div>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {dialogUpdateMode && (
            <Tooltip title={T('Delete user')}>
              <IconButton
                style={{ margin: theme.spacing(2) }}
                onClick={() => setConfirmationDialogOpen(true)}
                data-cy="delete-user-button"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ flex: '1 0 0' }} />
          <DialogActions>
            <Button
              data-cy="save-update-button"
              onClick={dialogUpdateMode ? updateAndClose : saveAndClose}
              color="primary"
              disabled={!dialogInputValidity}
              sx={{ fontWeight: 500 }}
            >
              {T('Save')}
            </Button>
            <Button
              data-cy="close-button"
              onClick={cleanAndClose}
              color="primary"
              sx={{ fontWeight: 500 }}
            >
              {T('Close')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onBackdropClick={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>{T('Warning')}</DialogTitle>
        <DialogContent>
          {T('Are you sure you want to delete this user?')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete();
            }}
            color="primary"
            data-cy="confirm-deletion-button"
          >
            {T('Yes')}
          </Button>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="primary"
          >
            {T('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
